<template>
  <!-- <div class="debug">
    class: {{ classes }}
    <br>
    data: {{ data }}
  </div> -->
  <p v-if="element === 'p'" :class="classes" v-html="text" />
  <h1 v-else-if="element === 'h1'" :class="classes" v-html="text" />
  <h2 v-else-if="element === 'h2'" :class="classes" v-html="text" />
  <h3 v-else-if="element === 'h3'" :class="classes" v-html="text" />
  <h4 v-else-if="element === 'h4'" :class="classes" v-html="text" />
  <h5 v-else-if="element === 'h5'" :class="classes" v-html="text" />
  <h6 v-else-if="element === 'h6'" :class="classes" v-html="text" />
  <h6 v-else-if="element === 'h6'" :class="classes" v-html="text" />
  <div v-else :class="classes" v-html="text" />
</template>

<script setup>
const props = defineProps({
  block: Object,
  data: Object,
})
const text = props.data?.text
const element = props.data?.element

const classes = [
  props.data?.fontSize 
    ? `text-${props.data?.fontSize}`
    : (element === 'p')
      ? 'text-base'
      : `text-${element}`,
  props.data?.align ? `text-${props.data?.align}` : null
].filter(str => str).join(' ')
</script>

<style lang="scss" scoped>
.text-h1, 
.text-h2, 
.text-h3, 
.text-h4, 
.text-h5, 
.text-h6, 
.text-lead, 
.text-base, 
.text-meta, 
.text-mini {
  // @include box();
  // @apply mt-4;
  @apply max-w-3xl;

  // &:not(.is-root).is-first {
  //   @apply mt-0;
  // }

  &.is-root {
    // @apply col-prose;
  }

  // &.is-first {
  //   @apply mt-0;
  // }

  &.is-last {
    // @apply mb-0;
  }
}

// h1,
.text-h1 {
  @apply mt-4;
  @apply font-serif font-bold;
  @apply text-4xl sm:text-5xl md:text-6xl;
}

// h2,
.text-h2 {
  @apply mt-4;
  @apply font-serif font-bold;
  @apply text-2xl md:text-3xl lg:text-4xl;
}

// h3,
.text-h3 {
  @apply mt-4;
  @apply font-serif font-bold;
  @apply text-xl md:text-2xl;
}

// h4,
.text-h4 {
  @apply mt-4;
  @apply font-bold;
  @apply md:text-lg;
}

// h5,
.text-h5 {
  @apply mt-4;
  @apply font-bold;
}

// h6,
.text-h6 {
  @apply mt-4;
  @apply font-bold;
}

.text-lead {
  @apply text-lg leading-7 md:text-xl md:leading-8;
}

// Body text line height
.text-base, .text-meta, .text-mini {
  // @apply leading-6;
}

// .h1, .h2, .h3, .h4, .h5, .h6 {
//   + .h1, + .h2, + .h3, + .h4, + .h5, + .h6 {
//     // @apply mt-4;
//   }
// }

// Links
.text-lead, .text-base, .text-meta, .text-mini, .text-sm, .text-xs {
  &:deep(a) {
    @apply underline text-blue-800 decoration-blue-600/40 duration-300;

    &:hover {
      @apply decoration-blue-600/80;
    }
  }
}

// .is-root {
//   &.text-h1,
//   &.text-h2,
//   &.text-h3,
//   &.text-h4,
//   &.text-h5,
//   &.text-h6 {
//     + .text-lead, + .text-base, + .text-meta, + .text-mini {
//       @apply mt-2;
//       @apply -mt-2;
//     }
//   }
// }
</style>
